angular.module('fingerink')
    .factory('companyService', ['$http', 'session', function ($http, session) {
        var sdo = {
         
            updateEmpresa: function (empresa) {
                return $http({
                    method: 'PUT',
                    url: 'companies/' + empresa.id,
                    crossDomain: true,
                    data: empresa
                });
            },
            uploadImageCompany: function (file) {
                var fd = new FormData();
                fd.append('file', file);
                return $http.post('companies/companyImage', fd, {
                    withCredentials: false,
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined,
                        'Authorization': 'Bearer ' + session.get().token
                    },
                    crossDomain: true
                });
            },
            companyImageURL: function () {
                return $http({
                    method: 'GET',
                    url: 'companies/companyImage',
                    crossDomain: true
                });
            },
            companyImageIdURL: function (id) {
                return $http({
                    method: 'GET',
                    url: 'companies/companyImage/'+id,
                    crossDomain: true
                });
            },
            uploadStampURLBase64: function (base64) {
                return $http({
                    method: 'POST',
                    url: 'companies/companyStampURL/base64',
                    crossDomain: true,
                    data: base64
                });
            },
            companyStampURL: function () {
                return $http({
                    method: 'GET',
                    url: 'companies/companyStamp',
                    crossDomain: true
                });
            }
            
            
        };
        return sdo;
}]);
